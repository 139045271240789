import React, { useState, useEffect, useRef } from 'react'

import GradientText from '@components/GradientText'
import { animateTextGradientOnMovement } from '@utils/gradientAnimation'
import style from './GradientPlayground.module.scss'
import classnames from 'classnames/bind'

import { gradientColor1, gradientColor2, gradientColor3 } from '@styles/variables.module.scss'

const cx = classnames.bind(style)

const GradientPlayground = () => {
  const [colors, setColors] = useState([gradientColor1, gradientColor2, gradientColor3])
  const [isTouch, changeIsTouch] = useState(false)

  const [colorStops, setColorStops] = useState(['0%', '20%', '60%'])
  const previewRef = useRef(null)

  const changeColor = (value, index) => {
    const newColors = [...colors]
    newColors[index] = value
    setColors(newColors)
  }

  const changeColorStop = (value, index) => {
    const newStops = [...colorStops]
    newStops[index] = value
    setColorStops(newStops)
  }

  useEffect(() => {
    const element = previewRef.current

    window.addEventListener('touchstart', () => !isTouch && changeIsTouch(true))
    window.addEventListener('mousemove', () => isTouch && changeIsTouch(true))
    if (isTouch) {
      element.removeEventListener('mousemove', e =>
        animateTextGradientOnMovement(e, element, { colors, stops: colorStops })
      )
      window.addEventListener('scroll', e =>
        animateTextGradientOnMovement(e, element, { colors, stops: colorStops, scroll: true })
      )
    } else {
      window.removeEventListener('scroll', e =>
        animateTextGradientOnMovement(e, element, { colors, stops: colorStops, scroll: true })
      )
      element.addEventListener('mousemove', e =>
        animateTextGradientOnMovement(e, element, { colors, stops: colorStops })
      )
    }

    return () => {
      window.removeEventListener('touchstart', () => !isTouch && changeIsTouch(true))
      window.removeEventListener('mousemove', () => isTouch && changeIsTouch(true))

      if (isTouch) {
        element.removeEventListener('mousemove', e =>
          animateTextGradientOnMovement(e, element, { colors, stops: colorStops })
        )
      } else {
        window.removeEventListener('scroll', e =>
          animateTextGradientOnMovement(e, element, { colors, stops: colorStops, scroll: true })
        )
      }
    }
  }, [previewRef, isTouch, colorStops, colors])

  return (
    <div className={cx('container')}>
      <div>
        <span>Controls</span>
        <div className={cx('inputGroup')}>
          <div className={cx('inputContainer')}>
            <label>First Color:</label>
            <input
              className={cx('input')}
              onChange={e => changeColor(e.target.value, 0)}
              type="text"
              value={colors[0]}
            ></input>
            <input
              className={cx('input', 'inputColor')}
              type="color"
              onChange={e => changeColor(e.target.value, 0)}
              value={colors[0]}
            ></input>
          </div>
          <div className={cx('inputContainer')}>
            <label>Stop (with unit):</label>
            <input
              className={cx('input', 'inputNumber')}
              type="text"
              onChange={e => changeColorStop(e.target.value, 0)}
              value={colorStops[0]}
            ></input>
          </div>
          <div className={cx('inputContainer')}>
            <label>Second Color:</label>
            <input
              className={cx('input')}
              type="text"
              onChange={e => changeColor(e.target.value, 1)}
              value={colors[1]}
            ></input>
            <input
              className={cx('input', 'inputColor')}
              type="color"
              onChange={e => changeColor(e.target.value, 1)}
              value={colors[1]}
            ></input>
          </div>
          <div className={cx('inputContainer')}>
            <label>Stop (with unit):</label>
            <input
              className={cx('input', 'inputNumber')}
              type="text"
              onChange={e => changeColorStop(e.target.value, 1)}
              value={colorStops[1]}
            ></input>
          </div>
          <div className={cx('inputContainer')}>
            <label>Third Color:</label>
            <input
              className={cx('input')}
              type="text"
              onChange={e => changeColor(e.target.value, 2)}
              value={colors[2]}
            ></input>
            <input
              className={cx('input', 'inputColor')}
              type="color"
              onChange={e => changeColor(e.target.value, 2)}
              value={colors[2]}
            ></input>
          </div>
          <div className={cx('inputContainer')}>
            <label>Stop (with unit):</label>
            <input
              className={cx('input', 'inputNumber')}
              type="text"
              onChange={e => changeColorStop(e.target.value, 2)}
              value={colorStops[2]}
            ></input>
          </div>
        </div>
      </div>
      <div className={cx('previewContainer')}>
        <span style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '1rem' }}>Gradient preview: </span>
        <div
          ref={previewRef}
          style={{
            width: '500px',
            maxWidth: '100%',
            height: '200px',
            backgroundImage: `radial-gradient(circle at 50% 50%, ${colors[0]} ${colorStops[0]}, ${colors[1]} ${
              colorStops[1]
            }, ${colors[2]} ${colorStops[2]})`,
          }}
        ></div>
      </div>
      <GradientText className={cx('gradientText')} tag="h1" type="h1" colors={colors} colorStops={colorStops}>
        We make insurance better for everyone
      </GradientText>
    </div>
  )
}

export default GradientPlayground
