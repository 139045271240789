import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import style from './Text.module.scss'
import { TYPES } from './constants'
import classnames from 'classnames'

const Text = forwardRef(({ tag: Tag, children, className, type, ...props }, ref) => (
  <Tag ref={ref} className={classnames(style[type], style['hyphens'], className)} {...props}>
    {children}
  </Tag>
))

Text.displayName = 'Text'

Text.propTypes = {
  tag: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(TYPES).isRequired,
}

export default Text
