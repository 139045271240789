export const TYPES = [
  'h1',
  'h2Serif',
  'h2Medium',
  'h2Bold',
  'h3',
  'h3Serif',
  'h4',
  'b1',
  'b1Bold',
  'b2',
  'b2Medium',
  'cta',
  'ctaBold',
  'list',
  'eyebrow',
  'fineprint1',
  'fineprint2',
]
export const TYPES_NAMES = {
  'Headline 1': 'h1',
  'Headline 2 Serif': 'h2Serif',
  'Headline 2 Medium': 'h2Medium',
  'Headline 2 Bold': 'h2Bold',
  'Headline 3': 'h3',
  'Headline 3 Serif': 'h3Serif',
  'Headline 4': 'h4',
  'Body copy 1': 'b1',
  'Body copy 1 bold': 'b1Bold',
  'Body copy 2': 'b2',
  'Body copy 2 medium': 'b2Medium',
  CTA: 'cta',
  'CTA Bold': 'ctaBold',
  List: 'list',
  Eyebrow: 'eyebrow',
  'Fineprint 1': 'fineprint1',
  'Fineprint 2': 'fineprint2',
}
